import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import virtual_lesson from "../../assets/images/blog-image/virtual_lesson.jpg";
import vr from "../../assets/images/blog-image/vr.jpg";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

function MyCoolCodeBlock({ code, language, showLineNumbers }) {
    return (
        <CodeBlock
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={vs2015}
        />
    );
}

const articleData = {
    id: 21,
    title: "Serverless React Applications",
    desc: "Come costruire app scalabili con AWS Lambda",
    img: "/blog-image/AWS.png",
    page: "blog/react-AWS",
    data: "27 Set 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "Quando si parla di web application, la scalabilità è un elemento fondamentale per soddisfare le esigenze delle aziende.",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        Quando si parla di web application, la
                                        scalabilità è un elemento fondamentale
                                        per soddisfare le esigenze delle
                                        aziende. In quest’ottica, sta acquisendo
                                        sempre più popolarità l'uso di
                                        applicazioni serverless in combinazione
                                        con React, ampiamente utilizzato per la
                                        creazione di interfacce utente
                                        dinamiche. In questo articolo,
                                        esploriamo come costruire applicazioni
                                        serverless con React utilizzando AWS
                                        Lambda, per soddisfare le esigenze di
                                        scalabilità.
                                    </p>

                                    <h3>Cos'è un'applicazione serverless?</h3>
                                    <p>
                                        In un contesto tecnico, "serverless" non
                                        significa che non ci siano server
                                        coinvolti; piuttosto, si riferisce al
                                        fatto che non è necessario gestire
                                        fisicamente i server da parte dello
                                        sviluppatore. L'infrastruttura
                                        sottostante è gestita dal provider di
                                        servizi cloud, consentendo agli
                                        sviluppatori di concentrarsi
                                        esclusivamente sulla logica
                                        dell'applicazione.
                                    </p>

                                    <p>
                                        AWS Lambda è uno dei servizi cloud più
                                        utilizzati per implementare
                                        un'architettura serverless. Consente di
                                        eseguire il codice in risposta a eventi,
                                        senza doversi preoccupare
                                        dell'infrastruttura sottostante. Questo
                                        lo rende un'opzione ideale per la
                                        creazione di applicazioni serverless.
                                    </p>

                                    <h3>
                                        Vantaggi dell'architettura serverless
                                    </h3>

                                    <ol>
                                        <li>
                                            <h5>Scalabilità automatica</h5>
                                            <p>
                                                Con un'architettura serverless,
                                                il servizio si espande e si
                                                riduce automaticamente in base
                                                alla quantità di traffico.
                                                Questo significa che è possibile
                                                gestire carichi di lavoro
                                                variabili senza dover prevedere
                                                o gestire manualmente
                                                l'allocazione delle risorse.
                                            </p>
                                        </li>
                                        <li>
                                            <h5>Riduzione dei costi</h5>
                                            <p>
                                                Dato che le spese vengono
                                                applicate esclusivamente in base
                                                all'effettivo utilizzo delle
                                                risorse, le applicazioni
                                                serverless possono dimostrarsi
                                                economicamente vantaggiose in
                                                confronto a un'infrastruttura
                                                tradizionale basata su server.
                                                In aggiunta, è da notare che
                                                molti servizi cloud offrono
                                                regolarmente un livello di
                                                servizio gratuito, il che
                                                agevola notevolmente l'inizio di
                                                progetti senza oneri iniziali.
                                            </p>
                                        </li>
                                        <li>
                                            <h5>Semplicità nella gestione</h5>
                                            <p>
                                                Senza la necessità di
                                                configurare o mantenere server,
                                                ci si può concentrare
                                                completamente sulla scrittura
                                                del codice dell'applicazione.
                                                Ciò semplifica notevolmente il
                                                processo di sviluppo e
                                                distribuzione.
                                            </p>
                                        </li>
                                    </ol>
                                    <h5>
                                        Costruire un'app serverless con React e
                                        AWS Lambda
                                    </h5>
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                    <br />
                                    <p>
                                        Analizziamo ora come creare
                                        un'applicazione serverless con React e
                                        AWS Lambda:
                                    </p>
                                    <ol>
                                        <li>
                                            <h5>
                                                Inizializzazione di un progetto
                                                React
                                            </h5>
                                            <p>
                                                Per iniziare, ci deve assicurare
                                                di avere Node.js e npm
                                                installati nel proprio sistema.
                                                Quindi, si procede con la
                                                creazione di un nuovo progetto
                                                React utilizzando il seguente
                                                comando:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`npx create-react-app serverless-react-app`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                            <br />
                                            <MyCoolCodeBlock
                                                code={`cd serverless-react-app`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <li>
                                            <h5>
                                                Aggiunta delle dipendenze AWS
                                                Amplify
                                            </h5>
                                            <p>
                                                Per facilitare l'integrazione di
                                                AWS Lambda nell'app React,
                                                utilizzeremo AWS Amplify, una
                                                libreria che semplifica
                                                l'accesso ai servizi AWS.
                                                Installa Amplify con il comando:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`npm install aws-amplify @aws-amplify/ui-react`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <li>
                                            <h5>Configurazione AWS Amplify</h5>
                                            <p>
                                                Dopo aver installato Amplify, si
                                                esegue il comando di
                                                configurazione per inizializzare
                                                il proprio progetto:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`amplify configure`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                            <br />
                                            <p>
                                                Per autenticarsi con il proprio
                                                account AWS e configurare un
                                                profilo, vanno seguite le
                                                istruzioni.
                                            </p>
                                        </li>
                                        <li>
                                            <h5>Creare una Funzione Lambda</h5>
                                            <p>
                                                Per aggiungere una funzione
                                                Lambda al progetto, eseguire il
                                                comando:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`amplify add function`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                            <br />
                                            <p>
                                                Rispondi alle domande relative
                                                alla configurazione della
                                                funzione Lambda - come il nome e
                                                il runtime. Amplify genererà
                                                automaticamente un file di
                                                esempio per la tua funzione
                                                Lambda.
                                            </p>
                                        </li>
                                        <li>
                                            <h5>
                                                Collegare la funzione Lambda
                                                all'app React
                                            </h5>
                                            <p>
                                                Ora che la funzione Lambda è
                                                stata creata, è possibile
                                                chiamarla dall'app React.
                                                Modifica il codice dell'app
                                                React per utilizzare la funzione
                                                Lambda:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`
import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
function App() {
    const [data, setData] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        try {
            const response = await API.get('lambdaFunction', '/items');
            setData(response);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="App">
            <h1>App Serverless con React</h1>
            <p>{data}</p>
        </div>
    );
}

export default App;
`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                            <br />
                                            <p>
                                                In questo esempio, stiamo
                                                utilizzando la libreria Amplify
                                                per effettuare una richiesta GET
                                                alla funzione Lambda chiamata
                                                "lambdaFunction". I dati
                                                restituiti dalla funzione Lambda
                                                vengono quindi visualizzati
                                                nell'app React.
                                            </p>
                                        </li>
                                        <li>
                                            <h5>
                                                Distribuzione dell'App React
                                                Serverless
                                            </h5>
                                            <p>
                                                Una volta completato il codice
                                                dell'app React, è possibile
                                                distribuirla in modo serverless
                                                utilizzando Amplify:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`amplify publish`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                    </ol>

                                    <p>
                                        Questo comando pubblicherà
                                        l'applicazione e tutte le risorse
                                        necessarie sulla tua infrastruttura AWS.
                                        Il risultato è un'app React
                                        completamente scalabile e gestita senza
                                        alcuna preoccupazione per
                                        l'infrastruttura sottostante.
                                    </p>

                                    <p>
                                        Le applicazioni serverless offrono
                                        un'infrastruttura altamente scalabile e
                                        gestibile per le applicazioni React. AWS
                                        Lambda e AWS Amplify semplificano il
                                        processo di sviluppo e distribuzione,
                                        consentendo di investire energie e
                                        risorse nella logica dell'applicazione.
                                        Hai bisogno di approfondire il mondo
                                        React? Mettiti in contatto con i nostri
                                        tecnici, raccontaci il tuo progetto.
                                    </p>

                                    <br />
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>

                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};
export default BlogDetails;
